import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import leak from '../../img/water-leak.png'

// css classes
import classes from '../Dashboard/dashboard.module.css'

const WaterLossReportBox = () => {
  return (
    <WhiteBox className={'min-height-443px'}>
      <img height={98} src={leak} alt="leak" />
      <h3>Well Production</h3>
      <p>
        Generate Well Production for a given date range to be used in a water loss report.
      </p>
      <div className={classes.buttonContainer}>
        <Link to={'/reports/ga/wlr'} className={classes.button}>Generate Report</Link>
      </div>
    </WhiteBox>
  )
}

export default WaterLossReportBox